<template>
  <div class="row form-data">
    <b-form :form="form" @submit.prevent="handleSubmitForm()" class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-4">
            <b-form-group label="CPF">
              <b-form-input
                :disabled="!isAdd"
                v-model="form.cpf"
                :class="$v.form.cpf.$error && 'is-invalid'"
                v-mask="cpfMask"
                placeholder="CPF"
              />

              <b-form-invalid-feedback v-if="$v.form.cpf.$error">
                Digite um CPF valido
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Nome">
              <b-form-input
                :class="$v.form.name.$error && 'is-invalid'"
                v-model="form.name"
                placeholder="Nome"
              />
              <b-form-invalid-feedback v-if="$v.form.name.$error">
                Digite um nome válido
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Nacionalidade">
              <multiselect
                close-on-select
                :show-labels="false"
                :class="$v.form.country.$error && 'is-invalid'"
                v-model="form.country"
                :options="countries"
                placeholder="Selecione sua nacionalidade"
                label="name"
              >
              </multiselect>
              <b-form-invalid-feedback v-if="$v.form.country.$error">
                Digite sua nacionalidade
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Profissão">
              <b-form-input
                :class="$v.form.profession.$error && 'is-invalid'"
                v-model="form.profession"
                placeholder="Profissão"
              />
              <b-form-invalid-feedback v-if="$v.form.profession.$error">
                Digite sua profissão
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="RG">
              <b-form-input
                :class="$v.form.rg.$error && 'is-invalid'"
                v-model="form.rg"
                placeholder="RG"
                @keypress="isNumber($event)"
              />
              <b-form-invalid-feedback
                v-if="$v.form.rg.$error || !$v.form.rg.maxLength"
              >
                RG inválido
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Estado Cívil">
              <multiselect
                :close-on-select="true"
                :show-labels="false"
                :class="$v.form.marital_status.$error && 'is-invalid'"
                v-model="form.marital_status"
                :options="marital_status"
                placeholder="Selecione seu estado civil"
                label="name"
              >
              </multiselect>
              <b-form-invalid-feedback v-if="$v.form.marital_status.$error">
                Selecione seu estado civil
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="Gênero">
              <multiselect
                :close-on-select="true"
                :show-labels="false"
                :class="$v.form.gender.$error && 'is-invalid'"
                v-model="form.gender"
                :options="genders"
                placeholder="Selecione seu Gênero"
                label="name"
              >
              </multiselect>
              <b-form-invalid-feedback v-if="$v.form.gender.$error">
                Selecione seu gênero
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group label="E-mail">
              <b-form-input v-model="form.email" placeholder="E-mail" />
              <b-form-invalid-feedback
                v-if="$v.form.email.$error || $v.form.email.email"
              >
                Digite um e-mail válido
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <!-- Endereço -->
          <hr />
          <div class="px-3 row">
            <div class="col-md-4">
              <b-form-group label="CEP" class="input-loading">
                <b-form-input
                  autocomplete="off"
                  v-model="form.zip_code"
                  :class="$v.form.zip_code.$error && 'is-invalid'"
                  @keyup="getZipCode"
                  v-mask="'#####-###'"
                  placeholder="CEP"
                />
                <b-icon icon="disc" v-if="loading_zip_code" />
                <b-form-invalid-feedback v-if="$v.form.zip_code.$error">
                  CEP inválido
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group label="Logradouro">
                <b-form-input
                  :disabled="!$v.form.zip_code"
                  :class="$v.form.place.$error && 'is-invalid'"
                  v-model="form.place"
                  placeholder="Logradouro"
                />
                <b-form-invalid-feedback
                  v-if="$v.form.place.$error && $v.form.zip_code.$error"
                >
                  Digite o logradouro
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group label="Bairro">
                <b-form-input
                  :class="$v.form.neighborhood.$error && 'is-invalid'"
                  v-model="form.neighborhood"
                  :disabled="$v.form.zip_code.$error"
                  placeholder="Bairro"
                />
                <b-form-invalid-feedback v-if="$v.form.neighborhood.$error">
                  Digite o seu bairro
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group label="Cidade">
                <b-form-input
                  v-model="form.city"
                  :class="$v.form.city.$error && 'is-invalid'"
                  :disabled="!$v.form.zip_code && !$v.form.city"
                  placeholder="Cidade"
                />
                <b-form-invalid-feedback v-if="$v.form.city.$error">
                  Digite a sua cidade
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group label="UF">
                <b-form-input
                  v-model="form.state"
                  :class="$v.form.state.$error && 'is-invalid'"
                  :disabled="!$v.form.zip_code"
                  placeholder="UF"
                />
                <b-form-invalid-feedback v-if="$v.form.state.$error">
                  Digite o seu estado
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="col-md-2">
              <b-form-group label="Número">
                <b-form-input
                  :class="$v.form.number.$error && 'is-invalid'"
                  @keypress="isNumber($event)"
                  v-model="form.number"
                  placeholder="Número"
                />
                <b-form-invalid-feedback v-if="!$v.form.number.required">
                  Digite o número
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="col-md-2">
              <b-form-group label="Complemento">
                <b-form-input
                  v-model="form.complement"
                  placeholder="Complemento"
                />
              </b-form-group>
            </div>
          </div>
          <b-form-checkbox class="pl-0 ml-3" v-model="form.is_main">
            Responsável pincipal
          </b-form-checkbox>
        </div>
      </div>
      <div class="mt-5 mx-3 d-flex w-100 justify-content-between" v-if="!isAdd">
        <b-button
          variant="outline-danger"
          class="mr-3"
          v-if="!form.is_main && id !== user.id"
          @click="handleConfirmDelete()"
        >
          <b-spinner v-if="loadingSubmit" small class="mr-2" />
          <span>Excluir responsável</span>
        </b-button>
        <section v-else>
          <span v-if="id === user.id">Este é o seu perfil</span>
          <span v-if="form.is_main" class="text-primary">
            Reponsável principal
          </span>
        </section>
        <div class="d-flex align-items-center gap-4">
          <b-button @click="$emit('submited')" variant="light" class="mr-3">
            <span>Cancelar</span>
          </b-button>
          <b-button
            :disabled="invalid_cep || loadingSubmit"
            type="submit"
            variant="primary"
          >
            <b-spinner v-if="loadingSubmit" small class="mr-2" />
            <span>Salvar e fechar</span>
          </b-button>
        </div>
      </div>
      <div v class="mt-5 mx-3 d-flex w-100 justify-content-end" v-else>
        <b-button
          :disabled="invalid_cep || loadingSubmit"
          type="submit"
          variant="primary"
        >
          <b-spinner v-if="loadingSubmit" small class="mr-2" />
          <span>Salvar e fechar</span>
        </b-button>
      </div>
    </b-form>
  </div>
</template>
<script>
import { api } from "@/services/api";
import { isValidCpf } from "@/utils/validations/cpf";
import { mapActions, mapGetters } from "vuex";
import { mask } from "vue-the-mask";
import { validationMixin } from "vuelidate";
import { cpf } from "cpf-cnpj-validator";
import countries from "@/utils/countries.json";
import { required, maxLength, email } from "vuelidate/lib/validators";
import { isNumber } from "@/utils/validations/isNumber.js";

export default {
  mixins: [validationMixin],
  directives: { mask },
  props: {
    id: {
      type: [Number, String],
      require: true,
    },
    isAdd: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {
      marital_status: [],
      countries: [],
      genders: [],
      form: {},
      processingDelete: false,
      isBusy: true,
      loadingImage: false,
      imageUrl: "",
      cpfMask: "###.###.###-##",
      loadingSubmit: false,
      loading_cnpj: false,
      loading_zip_code: false,
      invalid_cep: false,
      valid_cpf: false,
      isNumber,
    };
  },
  validations: {
    form: {
      cpf: {
        required,
        cpf: (num) => cpf.isValid(num),
      },
      name: { required },
      country: { required },
      profession: { required },
      rg: { required, maxLength: maxLength(12) },
      marital_status: { required },
      gender: { required },
      email: { required, email },
      place: { required },
      number: { required },
      zip_code: { required },
      neighborhood: { required },
      city: { required },
      state: { required },
    },
  },
  computed: {
    ...mapGetters(["user", "is_preview", "preview_personify"]),
  },
  methods: {
    ...mapActions([
      "get_legal_responsible",
      "edit_legal_responsible",
      "add_legal_responsible",
      "get_marital_status",
      "get_genders",
      "get_all_legal_personnel",
      "show_delete",
      "delete_legal_responsible",
    ]),

    handleValidateCpf(event) {
      const cpf = event.target.value.replace(/\D/g, "");
      this.valid_cpf = isValidCpf(cpf);
    },

    handleAddLegalResponsible() {
      this.add_legal_responsible(this.getFormDataObject())
        .then(({ status }) => {
          if ([200, 201].includes(status)) {
            this.$emit("submited");
          }
        })
        .finally(() => (this.loadingSubmit = false));
    },

    handleEditLegalResponsible() {
      this.edit_legal_responsible({
        id: this.id,
        ...this.getFormDataObject(),
      })
        .then(({ status }) => {
          if ([200, 201].includes(status)) {
            this.$emit("submited");
          }
        })
        .finally(() => (this.loadingSubmit = false));
    },

    handleSubmitForm() {
      this.$v.form.$touch();
      if (!this.$v.form.$anyError && !this.$v.$anyError) {
        this.loadingSubmit = true;
        if (this.isAdd) {
          this.handleAddLegalResponsible();
        } else {
          this.handleEditLegalResponsible();
        }
      }
    },

    getZipCode() {
      let zipCode = this.form.zip_code;
      if (zipCode) {
        zipCode = zipCode.replace(/[^\d]/g, "");
        if (zipCode.length === 8) {
          this.loading_zip_code = true;
          api
            .get(`/homologation/get-zip-code/${zipCode}/`)
            .then(({ data }) => {
              this.invalid_cep = data.erro;
              this.form = {
                ...this.form,
                city: data.localidade,
                state: data.uf,
                place: data.logradouro,
                neighborhood: data.bairro,
              };
            })
            .catch(() => {
              this.loading_zip_code = false;
            })
            .finally(() => (this.loading_zip_code = false));
        }
      }
    },

    getFormDataObject() {
      return {
        name: this.form.name,
        email: this.form.email,
        is_main: this.form.is_main || false,
        enterprise: this.is_preview
          ? this.preview_personify?.id
          : this.user?.enterprise?.id,
        profession: this.form.profession,
        rg: this.form.rg?.replace(/[^\d]/g, ""),
        cpf: this.form.cpf?.replace(/[^\d]/g, ""),
        marital_status: this.form.marital_status.id,
        gender: this.form.gender.id,
        address: {
          place: this.form.place,
          number: this.form.number,
          complement: this.form.complement,
          zip_code: this.form.zip_code?.replace(/[^\d]/g, ""),
          neighborhood: this.form.neighborhood,
          city: this.form.city,
          state: this.form.state,
          country: this.form.country.code,
        },
      };
    },

    getMarital() {
      this.get_marital_status().then(({ results }) => {
        this.marital_status = results;
      });
    },

    getGenders() {
      this.get_genders().then(({ results }) => {
        this.genders = results;
      });
    },

    getEdit() {
      this.get_legal_responsible({ id: this.id }).then(({ data }) => {
        this.form = {
          ...data,
          place: data.address.place,
          number: data.address.number,
          complement: data.address.complement,
          zip_code: data.address.zip_code,
          neighborhood: data.address.neighborhood,
          city: data.address.city,
          state: data.address.state,
          country: data.address.country,
        };
        this.$nextTick(() => {
          this.form.country = countries.find(
            (country) => data.address.country === country.code
          );
          this.cpfMask = "###.###.###-##";
        });
        delete this.form.address;
      });
    },

    handleConfirmDelete() {
      if (this.form.is_main || this.id === this.user.id) {
        return;
      }
      this.show_delete({
        in_delete: true,
        message: `Tem certeza que deseja excluir o responsável ${this.form.name}?`,
      });
    },

    handleDeleteLegalResponsible() {
      this.delete_legal_responsible(this.id).then(() => {
        this.$emit("submited");
        this.show_delete({
          in_delete: false,
        });
      });
    },
  },

  beforeDestroy() {
    this.form = null;
  },
  mounted() {
    this.countries = countries;
    if (!this.isAdd) {
      this.getEdit();
    }
    this.getMarital();
    this.getGenders();

    this.$root.$on("confirm-close-delete", (isDelete) => {
      if (isDelete) {
        this.handleDeleteLegalResponsible();
      }
    });
  },
};
</script>
